.calendarSection {
  background-color: var(--background-color);
  height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;

  &.collapsed {
    background-color: unset;

    svg {
      transform: rotate(180deg);
      transition: transform 0.3s ease-in-out;
    }
  }

  &.expanded {
    svg {
      transition: transform 0.3s ease-in-out;
    }
  }
}

.calendarToggler {
  width: 41px;
  height: 41px;
  border-radius: 100%;
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}
