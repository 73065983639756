.editor-input {
  min-height: 150px;
  resize: none;
  font-size: 15px;
  caret-color: var(--text-color);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 15px 10px;
}

.editor-container {
  background: var(--background-color);
  position: relative;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 15px;
  left: 10px;
  font-size: 15px;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 8px;
  position: relative;
  color: var(--text-color);
  font-style: unset;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 24px;
  color: var(--text-color);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-b {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
}

.editor-heading-h3 {
  font-size: 12px;
  color: var(--text-color);
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-list-ul {
  padding: 0;
  margin: 0;
  margin-left: 16px;
}

.editor-listitem {
  margin: 8px 16px 8px 16px;
}

.editor-nested-listitem {
  list-style-type: none;
}

.editor-listitem-checked,
.editor-listitem-unchecked {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 24px;
  padding-right: 24px;
  list-style-type: none;
  outline: none;
}

.editor-listitem-checked {
  text-decoration: line-through;
}

.editor-listitem-unchecked:before,
.editor-listitem-checked:before {
  content: '';
  width: 16px;
  height: 16px;
  top: 2px;
  left: 0;
  cursor: pointer;
  display: block;
  background-size: cover;
  position: absolute;
}

.editor-listitem-unchecked[dir='rtl']:before,
.editor-list-item-checked[dir='rtl']:before {
  left: auto;
  right: 0;
}

.editor-listitem-unchecked:focus:before,
.editor-list-item-checked:focus:before {
  box-shadow: 0 0 0 2px #a6cdfe;
  border-radius: 2px;
}

.editor-listitem-unchecked:before {
  border: 1px solid #999;
  border-radius: 2px;
}

.editor-listitem-checked:before {
  border: 1px solid rgb(61, 135, 245);
  border-radius: 2px;
  background-color: #3d87f5;
  background-repeat: no-repeat;
}

.editor-listitem-checked:after {
  content: '';
  cursor: pointer;
  border-color: #fff;
  border-style: solid;
  position: absolute;
  display: block;
  top: 6px;
  width: 3px;
  left: 7px;
  right: 7px;
  height: 6px;
  transform: rotate(45deg);
  border-width: 0 2px 2px 0;
}

.editor-link {
  color: rgb(33, 111, 219);
  text-decoration: none;
}

.editor-shell:focus {
  outline: none;
}

.editor-shell {
  margin-bottom: 14px;
  border-radius: 2px;
  max-width: 1100px;
  color: var(--text-color);
  position: relative;
  line-height: 1.7;
  font-weight: 400;
}

.editor-shell .editor-container {
  background: var(--background-color);
  position: relative;
  display: block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.editor-scroller {
  min-height: 150px;
  border: 0;
  display: flex;
  position: relative;
  outline: 0;
  z-index: 0;
  overflow: auto;
  resize: vertical;
}

.editor {
  flex: auto;
  position: relative;
  resize: vertical;
  z-index: -1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-width: 1px;
  border-color: var(--border-color);
  border-style: solid;
}

.editor div:focus-visible {
  outline: none;
}

.editor-paragraph:focus-visible {
  outline: none;
}

.editor-shell span.inline-editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.editor-shell .inline-editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .inline-editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
}

.editor-shell .inline-editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .inline-editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .inline-editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-shell .inline-editor-image.position-full {
  margin: 1em 0 1em 0;
}

.editor-shell .inline-editor-image.position-left {
  float: left;
  width: 25%;
  margin: 1em 1em 0 0;
}

.editor-shell .inline-editor-image.position-right {
  float: right;
  width: 25%;
  margin: 1em 0 0 1em;
}

.editor-shell .inline-editor-image .image-edit-button {
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px 8px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 60px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-shell .inline-editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-shell .inline-editor-image .image-caption-container {
  display: block;
  background-color: #f4f4f4;
  min-width: 100%;
  color: #000;
  overflow: hidden;
}

.editor-shell span.editor-image {
  cursor: default;
  display: inline-block;
  position: relative;
  user-select: none;
}

.editor-shell .editor-image img {
  max-width: 100%;
  cursor: default;
}

.editor-shell .editor-image img.focused {
  outline: 2px solid rgb(60, 132, 244);
  user-select: none;
}

.editor-shell .editor-image img.focused.draggable {
  cursor: grab;
}

.editor-shell .editor-image img.focused.draggable:active {
  cursor: grabbing;
}

.editor-shell .editor-image .image-caption-container .tree-view-output {
  margin: 0;
  border-radius: 0;
}

.editor-shell .editor-image .image-caption-container {
  display: block;
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  border-top: 1px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  min-width: 100px;
  color: #000;
  overflow: hidden;
}

.editor-shell .editor-image .image-caption-button {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 30%;
  padding: 10px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  min-width: 100px;
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.editor-shell .editor-image .image-caption-button:hover {
  background-color: rgba(60, 132, 244, 0.5);
}

.editor-shell .editor-image .image-edit-button {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  width: 35px;
  height: 35px;
  vertical-align: -0.25em;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  user-select: none;
}

.editor-shell .editor-image .image-edit-button:hover {
  background-color: rgba(60, 132, 244, 0.1);
}

.editor-shell .editor-image .image-resizer {
  display: block;
  width: 7px;
  height: 7px;
  position: absolute;
  background-color: rgb(60, 132, 244);
  border: 1px solid #fff;
}

.editor-shell .editor-image .image-resizer.image-resizer-n {
  top: -6px;
  left: 48%;
  cursor: n-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-ne {
  top: -6px;
  right: -6px;
  cursor: ne-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-e {
  bottom: 48%;
  right: -6px;
  cursor: e-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-se {
  bottom: -2px;
  right: -6px;
  cursor: nwse-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-s {
  bottom: -2px;
  left: 48%;
  cursor: s-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-sw {
  bottom: -2px;
  left: -6px;
  cursor: sw-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-w {
  bottom: 48%;
  left: -6px;
  cursor: w-resize;
}

.editor-shell .editor-image .image-resizer.image-resizer-nw {
  top: -6px;
  left: -6px;
  cursor: nw-resize;
}
