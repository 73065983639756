.mask {
  position: fixed;
  inset: 0;
  z-index: 1005;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.notification-pane {
  position: absolute;
  z-index: 100;
  top: 50px;
  left: 200px;
  width: 600px;
}

.notification-pane.is-collapsed {
  left: 80px;
}
