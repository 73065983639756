.row-responsive {
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 576px) {
  .row-responsive {
    gap: 0px;
    flex-direction: column;
    justify-content: space-around;
  }
}
