.rowGrid {
  :global {
    .ant-col {
      border-width: 2px;
      border-bottom: 2px;
      border-right: 2px;
      border-top: 0px;
      border-left: 0px;
      border-style: dotted;
      border-color: var(--border-color);
    }

    .ant-col:last-child {
      border-right: 0px;
    }
  }
}

.isRequired::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
