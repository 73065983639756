.drawer {
  :global {
    .ant-layout-header {
      background-color: var(--background-color);
      min-height: 120px;
      padding-inline: 0;
      padding-block: 8px;
      margin-bottom: 32px;
    }
    .ant-menu {
      border: none;

      .ant-menu-submenu {
        .ant-menu-submenu-title {
          padding-left: 0 !important;
        }

        text-align: left;
        .ant-menu-item {
          padding: 0 !important;
          margin-bottom: 32px;
        }
      }
      .ant-menu-item-only-child {
        padding: 0 !important;
        margin-bottom: 16px;
      }
      .ant-menu-item-group {
        .ant-menu-item-group-title {
          padding: 0 !important;
          margin-left: 4px !important;
        }
      }
    }
    .ant-layout-footer {
      padding: 0;
      background-color: var(--background-color);
      position: absolute;
      bottom: 24px;
    }
  }
}
