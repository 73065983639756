.tasksListWrapper {
  width: auto;
  background-color: var(--background-color);
  padding: 10px 14px;
  border-radius: 0px 5px 5px 5px;
  margin-bottom: 30px;
}
.taskPanel .ant-collapse-content-box {
  padding: 0;
  height: 100%;
}

.customPanel {
  :global {
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

:global {
  .ant-collapse-header {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
  }
}

.listPanelDraggingOver {
  border-top: 4px solid var(--warning-color);
  border-bottom: 4px solid var(--warning-color);
  opacity: 0.7;
}

.tasksListHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.expandToggler {
  padding-top: 7px;
}
