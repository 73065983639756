.taskItemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding-right: 5px;
  margin-bottom: 10px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  &:hover {
    cursor: pointer;

    .triggerIcon {
      display: flex;
    }
    .flagIconDisable {
      display: flex;
    }
  }

  .taskTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
  }

  .parentTask {
    color: var(--border-color);
    white-space: pre-wrap;
  }

  &.taskCompleted {
    background-color: var(--completed-task-bg-color);
    border: var(--background-color);

    .taskTitle {
      color: #a5a5a5;
    }
  }

  &.taskDragging {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 20px -5px,
      rgba(0, 0, 0, 0.1) 0px 5px 10px -5px;
    opacity: 0.7;
  }

  img {
    max-width: 200px;
    height: auto;
  }
}

.contentWrapper {
  padding: 8px 10px 8px 0;
  flex: 2;
}

.taskItemWrapperExpanded {
  align-items: unset;
}

.categoryIconWrapper {
  width: 5px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 3px 0px 0px 3px;
  align-items: center;
  margin-right: 10px;
  border-radius: 3px 0px 0px 3px;

  svg {
    font-size: 24px;
    display: none;
  }

  &:hover {
    width: 31px;

    svg {
      display: flex;
    }
  }
}

.categoryIconWrapperExpanded {
  width: 31px;
  height: auto;
  align-items: end;
  padding-top: 8px;
  padding-bottom: 8px;

  svg {
    display: unset;
  }
}
.triggerIcon {
  display: none;
  transition: transform 0.5s ease-in-out;
  svg {
    font-size: 14px;
  }
}

.taskActions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.taskActionsExpanded {
  align-items: flex-end;
  flex: 1;
  justify-content: flex-end;
  padding-top: 8px;
  padding-bottom: 8px;
}

.hiddenActions {
  display: flex;

  svg {
    font-size: 20px;
    color: #d6d6d6;
  }

  .warnIcon {
    :hover {
      color: var(--warning-color);
    }
  }

  .hiddenActionsItems {
    display: flex;
    max-height: 100px;
  }
}

.highlightedActions {
  display: flex;
  svg {
    font-size: 20px;
  }
}

.triggerIconActive {
  display: flex;
  transform: rotate(180deg);
  transition: transform 0.5s ease-in-out;
}

.flagicon {
  color: var(--warning-color);
}

.flagIconDisable {
  display: none;
  color: #d6d6d6;
  :hover {
    color: var(--warning-color);
  }
}

.priorityActionIcon {
  color: var(--warning-color);
}

.categoryIcon {
  color: var(--background-color);
}

.crossedOut {
  text-decoration: line-through;
}
