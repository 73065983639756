.expired {
  color: var(--delegation-expired);
}

.pending {
  color: var(--delegation-pending);
}

.refused {
  color: var(--delegation-refused);
}

.onhold {
  color: var(--delegation-on-hold, #ffa500);
}

.accepted {
  color: var(--delegation-accepted);
}
