.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.taskEditForm {
  :global {
    .ant-select-single.ant-select-lg .ant-select-selector {
      height: 50px;
    }
    .ant-select.save-select {
      .ant-select-selector {
        background-color: var(--business-category-color);
        color: var(--background-color);
      }
      .ant-select-arrow {
        color: var(--background-color);
      }
    }
    .ant-switch {
      .ant-switch-handle {
        top: 6px;
      }
      .ant-switch-inner {
        .ant-switch-inner-unchecked {
          margin-top: -25px;
        }
      }
    }

    .gradient {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: '';
        background: linear-gradient(135deg, #6253e1, #04befe);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  }
}
