.headerRow {
  height: auto;
  margin-left: 0px;
  margin-top: 0px;
}

.filtersContainer {
  background-color: var(--background-color);
  min-height: 48px;
  overflow: hidden;
  border-radius: 8px;
  margin-top: 8px;
  padding: 8px;
}
