.customPanel {
  :global {
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding: 0;
      }
    }
  }
}

.tasksListWrapper {
  width: auto;
  background-color: var(--background-color);
  padding: 10px 14px;
  border-radius: 0px 5px 5px 5px;
  margin-bottom: 30px;
}
