$notStartedColor: var(--not-started-color);
$inProgressPausedColor: var(--in-progress-paused-color);
$inProgressColor: var(--in-progress-active-color);
$needHelpColor: var(--need-help-color);
$enterTimeColor: var(--enter-time-color);
$completedColor: var(--completed-color);

$bgColorOptions: (
  'NotStarted': $notStartedColor,
  'InProgressPaused': $inProgressPausedColor,
  'InProgress': $inProgressColor,
  'NeedHelp': $needHelpColor,
  'EnterTime': $enterTimeColor,
  'Completed': $completedColor,
);

@mixin optionStyle($bgColor) {
  height: 55px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $bgColor;
}

.statusSelect {
  text-align: center;
}

.statusDropdown {
  padding: 0;

  :global {
    .ant-select-item-option-content {
      white-space: break-spaces;
      word-break: break-word;
    }

    .ant-select-item {
      border-radius: 0;
    }
  }

  :global {
    .ant-select-item-option-selected {
      background-color: blue !important;
      color: #fff !important;

      &:hover {
        background-color: blue !important;
        color: #fff !important;
      }
    }
  }
}

@each $option, $bgColor in $bgColorOptions {
  .option#{$option} {
    @include optionStyle($bgColor);

    &:hover {
      background-color: $bgColor !important;
    }
  }

  .picked#{$option} {
    :global {
      .ant-select-selector {
        background-color: $bgColor !important;
      }
      .ant-btn {
        background-color: $bgColor !important;
      }
    }
  }
}
