.cardContainer {
  width: 300px;
  min-height: 50px;
}

.cardNode {
  border-width: 4px;
  border-style: solid;
  padding-left: 10px;
  padding-right: 20px;
}

/* Styles for levels */

.level1 {
  border-color: var(--level-1-solid-color);
}

.level2 {
  border-color: var(--level-2-solid-color);
}

.level3 {
  border-color: var(--level-3-solid-color);
}

.level4 {
  border-color: var(--level-4-solid-color);
}

.level5 {
  border-color: var(--level-5-solid-color);
}

.level6 {
  border-color: var(--level-6-solid-color);
}

.level7 {
  border-color: var(--level-7-solid-color);
}

.level8 {
  border-color: var(--level-8-solid-color);
}

.shadowLevel1 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-1-solid-color);
}

.shadowLevel2 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-2-solid-color);
}

.shadowLevel3 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-3-solid-color);
}

.shadowLevel4 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-4-solid-color);
}

.shadowLevel5 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-5-solid-color);
}

.shadowLevel6 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-6-solid-color);
}

.shadowLevel7 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-7-solid-color);
}

.shadowLevel8 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-8-solid-color);
}
