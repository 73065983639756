.taskItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  margin-top: 8px;
  padding-left: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-left-width: 8px;
  border-left-style: solid;
}

.taskTitle {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
}
