.home {
  padding: 16px;
  max-width: 90vw;
  overflow-x: hidden;
  overflow-y: hidden;

  .titleRow {
    margin-bottom: 15px;
    color: #313131;
    text-align: center;

    span {
      font-size: 18px;
    }
  }

  :global {
    .MainLayout-container {
      border-radius: 5px;
      .MainLayout-background {
        background: var(--background-color);
      }

      .MainLayout-leftPanel {
        background: var(--background-color);
      }

      .MuiTableCell-root {
        .Label-text {
          color: var(--text-color);
        }
      }
    }
  }
}

.tasksFiltersColumn {
  height: 90vh;
  margin-bottom: 50px;
  overflow-x: hidden;
  overflow-y: auto;

  :global {
    .ant-tabs {
      .ant-tabs-nav {
        margin-bottom: 0px;
      }
    }
  }
}

.titleBox {
  background-color: var(--background-color);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 20px;
}

.dragDropColumn {
  text-align: center;

  .dashedWrapper {
    height: 65px;
    border: 1px dashed #707070;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 22px;
    }
  }

  .dragDropBody {
    height: 180px;
    margin-top: 14px;
  }
}

.mainSectionsRow {
  gap: 12px;

  @media (max-width: 1440px) {
    flex-direction: column;
  }
}

.taskListSection {
  flex: 2;
  overflow: hidden;
}

.dragDropSection {
  max-width: 400px;
  flex: 1;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  @media (max-width: 1440px) {
    flex: none;
    max-width: 100vw;
  }
}

.calendarContainer {
  height: 90vh;
  overflow: auto;
  float: right;
}
