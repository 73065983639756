.checkboxContainer {
  :global {
    span {
      display: inline-block;
    }

    label {
      .ant-checkbox {
        vertical-align: middle;
      }
    }
  }
}

.buttonContainer {
  margin-top: 8px;
  grid-column-gap: 16px;
}

.buttonLabel {
  vertical-align: middle;
  span {
    vertical-align: middle;
  }
}
