.taskGroupNode {
  color: var(--text-color);
  border-radius: 5px;
  border-style: solid;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  overflow-wrap: break-word;
}

.verticalStyle {
  height: 285px;
  padding: 100px 0;
  font-size: 8px;
  transition:
    width 1s ease-out,
    font-size 1s ease-out;
}

.horizontalStyle {
  width: 285px;
  padding: 0px 0;
  transition:
    height 1s ease-out,
    padding-top 1s ease-out;
}

.verticalMouseOver {
  font-size: 32px;
  width: 160px;
}

.verticalMouseOut {
  width: 50px;
}

.horizontalMouseOver {
  padding-top: 50px;
  height: 160px;
}

.horizontalMouseOut {
  height: 50px;
}

.level1 {
  background-color: var(--level-1-transparent-color);
  border-color: var(--level-1-solid-color);
}

.level2 {
  background-color: var(--level-2-transparent-color);
  border-color: var(--level-2-solid-color);
}

.level3 {
  background-color: var(--level-3-transparent-color);
  border-color: var(--level-3-solid-color);
}

.level4 {
  background-color: var(--level-4-transparent-color);
  border-color: var(--level-4-solid-color);
}

.level5 {
  background-color: var(--level-5-transparent-color);
  border-color: var(--level-5-solid-color);
}

.level6 {
  background-color: var(--level-6-transparent-color);
  border-color: var(--level-6-solid-color);
}

.level7 {
  background-color: var(--level-7-transparent-color);
  border-color: var(--level-7-solid-color);
}

.level8 {
  background-color: var(--level-8-transparent-color);
  border-color: var(--level-8-solid-color);
}
