.priorityRow {
  text-align: center;

  .title {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.priorityItem {
  margin-bottom: 15px;
  border: 1px dashed #707070;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  .title {
    font-size: 22px;
  }
}

.priorityItemDraggingOver {
  height: 135px;
  border: 2px solid var(--warning-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  background-color: var(--background-color);
  margin-bottom: 25px;
  transition: all 0.3s ease-out;

  .title {
    font-size: 20px;
  }
}
