.rowGrid {
  :global {
    .ant-col {
      border-width: 2px;
      border-bottom: 2px;
      border-right: 2px;
      border-top: 0px;
      border-left: 0px;
      border-style: dotted;
      border-color: var(--border-color);
    }

    .ant-col:last-child {
      border-right: 0px;
    }
  }
}

.rowRoleMappingTree {
  :global {
    .ant-col {
      border-width: 2px;
      border-bottom: 0px;
      border-right: 2px;
      border-top: 0px;
      border-left: 0px;
      border-style: dotted;
      border-color: var(--border-color);
    }

    .ant-col:last-child {
      border-right: 0px;
    }
  }
}
