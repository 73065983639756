/* favicon customizations */

.ccustom {
  fill: #fff;
}

body {
  font-family: 'Roboto', sans-serif;
}

/* ANTD customizations */

html,
body,
#root,
.ant-app {
  height: 100vh;
  background-color: var(--gray-background-color);
}

.ant-layout {
  height: 100%;
}

.antd-form-fix {
  max-width: 350px;
  width: 350px;
  margin: 0px;
}

@media screen and (max-width: 576px) {
  .antd-form-fix {
    margin: 0px 16px;
    width: 90%;
  }
}

.react-flow__nodes {
  --icon-disabled: #d6d6d6;
  --draft-color: #c3c3c3;
  --level-1-solid-color: #ff5252;
  --level-1-transparent-color: #ff525226;
  --level-2-solid-color: #ff3131;
  --level-2-transparent-color: #ff31a026;
  --level-3-solid-color: #b352ff;
  --level-3-transparent-color: #b352ff26;
  --level-4-solid-color: #5552ff;
  --level-4-transparent-color: #5552ff26;
  --level-5-solid-color: #1ba1e2;
  --level-5-transparent-color: #1ba1e226;
  --level-6-solid-color: #94e93f;
  --level-6-transparent-color: #94e93f26;
  --level-7-solid-color: #ffd146;
  --level-7-transparent-color: #ffd14626;
  --level-8-solid-color: #ffa449;
  --level-8-transparent-color: #ffa44926;
  --card-issue: #eb4a4a;
  /* These color are defined also in src/components/TaskFlowViewer/TaskNode.tsx */
}

/* React Quill customizations */

:root {
  --text-color: #1f1f1f;
  --background-color: #ffffffd8;
  --border-color: #424242;
  --input-background: #141414;
}

.fc-timegrid-slot {
  background-color: white;
}

.fc-daygrid-day-frame {
  background-color: white;
}

[data-theme='DARK'] {
  --white: #fff;
  --black: #000;
  --secondary: #ffffff73;
  --text-color: #ffffffd8;
  --secondary-text-color: #ffffffa6;
  --background-color: #1f1f1f;
  --offset-background-color: #333;
  --gray-background-color: #000;
  --blue-highlight: #2b2b3e;
  --border-color: #424242;
  --input-background: #141414;
  --not-started-color: #414141;
  --in-progress-active-color: #8bc34a;
  --need-help-color: #ffa726;
  --enter-time-color: #26c6da;
  --completed-color: #4caf50;
  --warning-color: #ff4d4f;
  --personal-category-color: #567c2e;
  --family-category-color: #108aa3;
  --business-category-color: #1249cc;
  --default-category-color: #424242;
  --completed-task-bg-color: #020202;
  --professional-category-color: #eb7734;
  --theocratic-category-color: #8f39a5;
  --friends-category-color: #ff5254;
  --club-category-color: #ff8441;
  --delegation-viewed: #1a7aff;
  --delegation-declined: #ff4d4f;
  --delegation-accepted: #9dd511;
  --delegation-expired: #a52a5a;
  --delegation-pending: #0000ff;
  --delegation-refused: #ff0000;
  --delegation-onhold: #ffa500;
  --user-avatar-background: #707070;
  --user-avatar-image-background: #d0d0d0;
  --selected-icon: #fff;
  --background-green: #7ec242;
  --licensing-met-color: #ffba33;
  --licensing-exceeded-color: #f44336;
  --licensing-ok-color: #84c54b;
  --licensing-loading-color: #707070;
  --selected-list-item: #424242;
  --audio-task-color: #7ec242;
  --avatar-max-count-solid: '#f56a00';
  --avatar-max-count-transparent: '#fde3cf';
}

[data-theme='LIGHT'] {
  --white: #fff;
  --black: #000;
  --secondary: #00000073;
  --text-color: #000;
  --secondary-text-color: #000000A6;
  --background-color: #fff;
  --offset-background-color: #eee;
  --gray-background-color: #f5f5f5;
  --blue-highlight: #e6f4ff;
  --border-color: #d9d9d9;
  --input-background: #fff;
  --not-started-color: #d8d8d8;
  --in-progress-active-color: #ffba33;
  --in-progress-paused-color: #ff4d4f;
  --need-help-color: #ff9543;
  --enter-time-color: #19d0e4;
  --completed-color: #7fc242;
  --warning-color: #ff4d4f;
  --personal-category-color: #7fc242;
  --family-category-color: #15bed1;
  --business-category-color: #1a7aff;
  --default-category-color: #d9d9d9;
  --completed-task-bg-color: #d8d8d8;
  --professional-category-color: #e68a55;
  --theocratic-category-color: #a160b2;
  --friends-category-color: #ff5254;
  --club-category-color: #ffd04d;
  --delegation-viewed: #1a7aff;
  --delegation-declined: #ff4d4f;
  --delegation-accepted: #9dd511;
  --delegation-expired: #a52a5a;
  --delegation-pending: #0000ff;
  --delegation-refused: #ff0000;
  --delegation-onhold: #ffa500;
  --user-avatar-background: #707070;
  --user-avatar-image-background: #d0d0d0;
  --selected-icon: #84c54b;
  --background-green: #f1ffd6;
  --licensing-met-color: #ffba33;
  --licensing-exceeded-color: #f44336;
  --licensing-ok-color: #9dd511;
  --licensing-loading-color: #707070;
  --selected-list-item: #f0f8ff;
  --audio-task-color: #7ec242;
  --avatar-max-count-solid: '#f56a00';
  --avatar-max-count-transparent: '#fde3cf';
}
