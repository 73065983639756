.reactFlowStyle {
  background-color: var(--background-color);
}

.cardContainer {
  width: 300px;
  min-height: 50px;

  :global {
    .ant-card {
      .ant-card-body {
        padding-top: 8px;
        padding-left: 0px;
        padding-bottom: 8px;
        padding-right: 0px;
      }
    }
  }
}

.cardNode {
  border-width: 4px;
  border-style: solid;
}

.cardIssue {
  background-color: var(--card-issue);
}

.cardAvatar {
  border: 1px solid;
  margin-right: 4;
  font-size: 16px;
  color: gray;
  font-weight: bold;
}

.cardModeDraft {
  background-color: var(--draft-color);
  color: #d3d3d3;
}

.cardModeCompleted {
  text-decoration: line-through;
  color: #5a5a5a;
}

.nodeContent {
  padding: 10px;
  text-align: center;
}

.nodeIcons {
  display: flex;
  justify-content: space-around;
}

.iconStyle {
  margin: 0 2px;
  font-size: 18px;
}

.iconContainer {
  position: relative;

  :global {
    .span {
      .ant-btn {
        border: 0px solid transparent;
      }

      .ant-btn-default {
        background-color: transparent;
      }
    }
  }
}

.iconAvatar {
  :global {
    .ant-btn {
      border: 1px solid transparent;
      margin-top: -4px;
      padding-top: 0px;
      .ant-btn-icon {
        width: 26px;
        height: 16px;
      }
    }
  }
}

.iconTooltip {
  position: absolute;
  top: -20px;
  left: -10px;
  width: auto;
  height: auto;
  padding: 2px;
  background: white;
  color: black;
}

.iconDisabled {
  color: var(--icon-disabled);
}

.flagIcon {
  color: var(--warning-color);
}

.iconAvatarStyle {
  margin: 0 5px;
  font-size: 20px;
  font-weight: bold;
}

.delegationExpired {
  background-color: var(--delegation-expired);
}
.delegationPending {
  background-color: var(--delegation-pending);
}
.delegationRefused {
  background-color: var(--delegation-refused);
}
.delegationOnhold {
  background-color: var(--delegation-onhold);
}
.delegationAccepted {
  background-color: var(--delegation-accepted);
}

.categoryIcon {
  color: #fff;
}

.filterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 10px;
}

.taskItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 8px;
  padding-left: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-left: 8px solid #a6db1f;
}

.taskTitle {
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

.taskStatus {
  padding-left: 20px;
}

/* Styles for levels */

.level1 {
  border-color: var(--level-1-solid-color);
}

.level2 {
  border-color: var(--level-2-solid-color);
}

.level3 {
  border-color: var(--level-3-solid-color);
}

.level4 {
  border-color: var(--level-4-solid-color);
}

.level5 {
  border-color: var(--level-5-solid-color);
}

.level6 {
  border-color: var(--level-6-solid-color);
}

.level7 {
  border-color: var(--level-7-solid-color);
}

.level8 {
  border-color: var(--level-8-solid-color);
}

.cardModeCompleted1 {
  background-color: var(--level-1-transparent-color);
}

.cardModeCompleted2 {
  background-color: var(--level-2-transparent-color);
}

.cardModeCompleted3 {
  background-color: var(--level-3-transparent-color);
}

.cardModeCompleted4 {
  background-color: var(--level-4-transparent-color);
}

.cardModeCompleted5 {
  background-color: var(--level-5-transparent-color);
}

.cardModeCompleted6 {
  background-color: var(--level-6-transparent-color);
}

.cardModeCompleted7 {
  background-color: var(--level-7-transparent-color);
}

.cardModeCompleted8 {
  background-color: var(--level-8-transparent-color);
}

.colorLevel1 {
  color: var(--level-1-solid-color);
}

.colorLevel2 {
  color: var(--level-2-solid-color);
}

.colorLevel3 {
  color: var(--level-3-solid-color);
}

.colorLevel4 {
  color: var(--level-4-solid-color);
}

.colorLevel5 {
  color: var(--level-5-solid-color);
}

.colorLevel6 {
  color: var(--level-6-solid-color);
}

.colorLevel7 {
  color: var(--level-7-solid-color);
}

.colorLevel8 {
  color: var(--level-8-solid-color);
}

.shadowLevel1 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-1-solid-color);
}

.shadowLevel2 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-2-solid-color);
}

.shadowLevel3 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-3-solid-color);
}

.shadowLevel4 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-4-solid-color);
}

.shadowLevel5 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-5-solid-color);
}

.shadowLevel6 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-6-solid-color);
}

.shadowLevel7 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-7-solid-color);
}

.shadowLevel8 {
  border-radius: 20px;
  box-shadow: 0 0 20px 10px var(--level-8-solid-color);
}
