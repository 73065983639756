.createTaskButton {
  :global {
    .ant-float-btn-body {
      background-color: #7ec242;

      svg {
        color: var(--text-color);
      }

      &:hover {
        color: #000;

        svg {
          color: #000;
        }
      }

      @media (min-width: 1200px) {
        margin-bottom: 38px;
      }
    }
  }
}

.modal {
  :global {
    .ant-modal-content {
      padding: 4px 24px;
    }
  }
}

.tab {
  :global {
    .ant-tabs-nav {
      margin: 0;
    }
  }
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

h3 {
  color: var(--text-color);
}

.tabIcon {
  font-size: 24px;
}
