.borders {
  position: relative;
  border: 1px solid #fff;
  outline: 1px solid red;
}

.expiredStatus {
  border: 1px solid #fff;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--delegation-expired);
}

.pendingStatus {
  border: 1px solid #fff;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--delegation-pending);
}

.refusedStatus {
  border: 1px solid #fff;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--delegation-refused);
}

.onholdStatus {
  border: 1px solid #fff;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--delegation-on-hold);
}

.acceptedStatus {
  border: 1px solid #fff;
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--delegation-accepted);
}

.avatarImage img {
  height: 100% !important;
}
