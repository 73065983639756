.sidebar {
  :global {
    .ant-layout-sider-children,
    .ant-menu {
      background-color: var(--background-color);
    }
    .ant-layout-sider-trigger {
      background: var(--background-color);
    }
  }
}

.menu {
  :global {
    .ant-menu-item-selected {
      background-color: var(--background-green);
      color: var(--selected-icon);
    }
  }
}
